const providersArray = document.querySelectorAll(".providers__item");

if (providersArray) {
  for (let index = 0; index < providersArray.length; index++) {
    const item = providersArray[index];

    const countArray = item.querySelectorAll(".count");
    for (let i = 0; i < countArray.length; i++) {
      const count = countArray[i];
      count.innerHTML = "0" + (index + 1);
    }
  }
}

const accordionContent = document.querySelectorAll(".answers__item");

accordionContent.forEach((item, index) => {
  let header = item.querySelector(".answers-title");
  header.addEventListener("click", () => {
    item.classList.toggle("is-open");

    let descrip = item.querySelector(".answers-body");
    let icon = item.querySelector(".icon");
    if (item.classList.contains("is-open")) {
      descrip.style.height = `${descrip.scrollHeight}px`;
      icon.style.transform = `rotate(135deg)`;
    } else {
      descrip.style.height = "0px";
      icon.style.transform = `rotate(0)`;
    }

    removeOpenedContent(index);
  });
});

function removeOpenedContent(index) {
  accordionContent.forEach((item2, index2) => {
    if (index != index2) {
      item2.classList.remove("is-open");
      let descrip = item2.querySelector(".answers-body");
      let icon = item2.querySelector(".icon");
      descrip.style.height = "0px";
      icon.style.transform = `rotate(0)`;
    }
  });
}

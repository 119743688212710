const tabInNode = document.querySelector(".tab-in-node");
const tabNavBtns = tabInNode?.querySelectorAll(".tab__nav-btn");
const tabPanels = tabInNode?.querySelectorAll(".tab__panel");

if (tabNavBtns) {
  for (let i = 0; i < tabNavBtns.length; i++) {
    const tabBtn = tabNavBtns[i];

    tabBtn.addEventListener("click", () => {
      tabNavBtns.forEach(function (tabBtn) {
        tabBtn.classList.remove("tab-btn--active");
      });

      tabBtn.classList.add("tab-btn--active");
      for (let index = 0; index < tabPanels.length; index++) {
        const panel = tabPanels[index];
        panel.classList.remove("tab__panel--active");
      }

      tabPanels[i].classList.add("tab__panel--active");
    });
  }
}

const tabInToken = document.querySelector(".tab-in-token");
const tabTokenNavBtns = tabInToken?.querySelectorAll(".tab__nav-btn");
const tabTokenPanels = tabInToken?.querySelectorAll(".tab__panel");

if (tabTokenNavBtns) {
  for (let i = 0; i < tabTokenNavBtns.length; i++) {
    const tabBtn = tabTokenNavBtns[i];

    tabBtn.addEventListener("click", () => {
      tabTokenNavBtns.forEach(function (tabBtn) {
        tabBtn.classList.remove("tab-btn--active");
      });

      tabBtn.classList.add("tab-btn--active");
      for (let index = 0; index < tabTokenPanels.length; index++) {
        const panel = tabTokenPanels[index];
        panel.classList.remove("tab__panel--active");
      }
      tabTokenPanels[i].classList.add("tab__panel--active");
    });
  }
}

document.addEventListener("DOMContentLoaded", function () {
  const header = document.querySelector(".header");
  const page = document.querySelector(".page__body");
  const burgerWrapper = document.querySelector(".burger__wrapper");
  const burger = document.querySelector(".burger");
  // var openMenuButton = document.getElementById('openmenu');

  burgerWrapper?.addEventListener("click", (event) => {
    event.preventDefault();
    burger.click();
  });

  window.addEventListener("scroll", function () {
    // page.classList.remove('menuopen');
    if (window.scrollY >= 100) {
      header.classList.add("topheader");
    } else {
      header.classList.remove("topheader");
    }
    if (window.scrollY >= 300) {
      header.classList.add("sticky");
    } else {
      header.classList.remove("sticky");
    }
  });
});

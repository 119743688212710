const { auto } = require("@popperjs/core");

var quarterSlider = new Swiper(".quarter-slider", {
  loop: false,
  grabCursor: true,
  // centeredSlides: true,
  speed: 1200,
  slidesPerView: auto,
  spaceBetween: 0,
  // freeMode: false,
  breakpoints: {
    320: {
      slidesPerView: 2,
      spaceBetween: 0,
    },
    576: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    992: {
      slidesPerView: 4,
      spaceBetween: 0,
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 0,
    },
  },
  navigation: {
    nextEl: ".quarter-button-next",
    prevEl: ".quarter-button-prev",
  },
});

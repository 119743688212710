document.addEventListener("DOMContentLoaded", () => {
  const sr = ScrollReveal({
    distance: "60px",
    duration: 900,
    delay: 400,
    // reset: true
  });

  sr.reveal(`.hero-img`, {
    origin: "left",
    delay: 300,
    distance: "100px",
  });
  sr.reveal(`.section__title`, {
    origin: "top",
    delay: 500,
    distance: "20px",
  });
  sr.reveal(`.market-slide`, {
    origin: "bottom",
    delay: 150,
    interval: 280,
    distance: "60px",
  });

  sr.reveal(`.providers__item .item-content`, {
    origin: "bottom",
    delay: 150,
    distance: "60px",
    interval: 280,
  });

  sr.reveal(`.mai-img`, { origin: "left", delay: 350, distance: "170px" });
  sr.reveal(`.mai-content`, { origin: "right", delay: 400, distance: "170px" });

  sr.reveal(`.tokenomics-diagram`, {
    origin: "left",
    delay: 450,
    distance: "170px",
  });
  sr.reveal(`.tokenomics-table`, {
    origin: "right",
    delay: 350,
    distance: "120px",
  });

  sr.reveal(`.questions__content`, {
    origin: "left",
    delay: 300,
    distance: "70px",
  });
  sr.reveal(`.questions__img`, {
    origin: "right",
    delay: 350,
    distance: "170px",
  });
});

import GraphModal from "graph-modal";
import GraphTabs from "graph-tabs";
import "./vendor/dynamic_adapt.js";
import { burger } from "./functions/burger";
const modal = new GraphModal();
const tabsBay = new GraphTabs("tab-bay");
const tabsPlatform = new GraphTabs("tab-platform");

import "simplebar";
new SimpleBar(document.getElementById("market-card"), {
  autoHide: false,
});

let inputs = document.querySelectorAll('input[type="tel"]');
let im = new Inputmask("+7 (999) 999-99-99");
im.mask(inputs);

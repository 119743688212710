(function () {
  const body = document.querySelector("body");
  const header = document.querySelector(".header");
  const burgerWrap = document.querySelector(".burger__wrapper");
  const burger = document?.querySelector("[data-burger]");
  const menu = document?.querySelector("[data-menu]");
  const menuItems = document?.querySelectorAll("[data-menu-item]");
  const overlay = document?.querySelector("[data-menu-overlay]");

  burger?.addEventListener("click", (e) => {
    burgerWrap.classList.toggle("burger--active");
    burger.classList.toggle("burger--active");
    menu.classList.toggle("menu--active");
    header.classList.toggle("menu--active");

    if (menu.classList.contains("menu--active")) {
      burger.setAttribute("aria-expanded", "true");
      burger.setAttribute("aria-label", "Close menu");
      header.classList.add("menu--active");
      // body.style.overflow = 'hidden';
    } else {
      burger.setAttribute("aria-expanded", "false");
      burger.setAttribute("aria-label", "Open menu");
      header.classList.remove("menu--active");
      // body.style.overflow = 'scroll';
    }
  });

  overlay?.addEventListener("click", () => {
    burger.setAttribute("aria-expanded", "false");
    burger.setAttribute("aria-label", "Open menu");
    burgerWrap.classList.remove("burger--active");
    burger.classList.remove("burger--active");
    menu.classList.remove("menu--active");
    header.classList.remove("menu--active");
    // body.style.overflow = 'scroll';
  });

  menuItems?.forEach((el) => {
    el.addEventListener("click", () => {
      burger?.setAttribute("aria-expanded", "false");
      burger?.setAttribute("aria-label", "Open menu");
      burgerWrap.classList.remove("burger--active");
      burger.classList.remove("burger--active");
      menu.classList.remove("menu--active");
      header.classList.remove("menu--active");
      // body.style.overflow = 'scroll';
    });
  });
})();

const modal = document.querySelector(
  '.graph-modal__container[data-graph-target="select-language"]'
);
const btnLangs = document.querySelectorAll(".btn-lang");
const flagBtnLangs = document.querySelectorAll(".btn-lang .flag");
const langBtnLangs = document.querySelectorAll(".btn-lang .lang");

btnLangs?.forEach((btnLang) => {
  btnLang.addEventListener("click", () => {
    const languageItems = document.querySelectorAll(".language__list button");
    const flagBtnLang = btnLang.querySelector(".btn-lang .flag");
    const langBtnLang = btnLang.querySelector(".btn-lang .lang");

    if (languageItems.length > 0) {
      languageItems.forEach((languageItem) => {
        languageItem.addEventListener("click", (e) => {
          langBtnLang.innerHTML = languageItem.querySelector(".lang").innerHTML;
          flagBtnLang.innerHTML = languageItem.querySelector(".flag").innerHTML;

          langBtnLangs.forEach((langItem) => {
            langItem.innerHTML = languageItem.querySelector(".lang").innerHTML;
          });
          flagBtnLangs.forEach((flagItem) => {
            flagItem.innerHTML = languageItem.querySelector(".flag").innerHTML;
          });

          modal.classList.remove("graph-modal-open");
          modal.classList.remove("animate-open");
          modal.classList.remove("fade");
          document.querySelector(".graph-modal").classList.remove("is-open");
          document.querySelector("body").classList.remove("disable-scroll");
          document.querySelector("body").style.removeProperty("padding-right");
        });
      });
    }
  });
});
